<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarCase />
    </template>
    <template v-slot:content>
      <FullViewProfile
        v-if="profile"
        :profile="profile"
      />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarCase from '@/viewsNew/SidebarCase';
import FullViewProfile from '@/components/full-view/Profile';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Layout,
    SidebarCase,
    FullViewProfile
  },
  async created () {
    this.setCurrentResults();
  },
  watch: {
    profile: {
      immediate: true,
      handler () {
        if (this.profile === 'redirect') this.$router.push(`/case/${this.$route.params.id}/${this.$route.params.searchId}`);
      }
    }
  },
  computed: {
    ...mapGetters(['getCurrentResults', 'getCurrentTagged', 'getProfile']),

    profile () {
      const getProfile = this.getCurrentResults.find(profile => profile.oid === this.$route.params.profileId);
      let profile = null;

      if (getProfile) profile = getProfile;
      else {
        this.setCurrentTagged();
        const tagProfiles = [];
        this.getCurrentTagged.forEach(tag => {
          tag.nodes.forEach(profile => {
            tagProfiles.push(profile);
          });
        });
        const resultProfile = tagProfiles.filter(profile => profile.oid === this.$route.params.profileId);
        if (resultProfile.length) {
          profile = resultProfile[0];
        } else
        if (this.getProfile) {
          profile = this.getProfile;
        } else {
          profile = 'redirect';
        }
      }

      return profile;
    }
  },
  methods: {
    ...mapActions(['setCurrentResults', 'setCurrentTagged'])
  }
};
</script>
