<template>
  <div class="bg-white br-5 overflow-hidden bs-default min-h-100">
    <div class="d-flex align-center jc-space-between bg-form br-top-left-5 br-top-right-5 px-24 py-12">
      <div class="d-flex align-center">
        <TagTooltip
          class="mr-20"
          :profiles="profile"
          size="small"
          position-x="left"
          position-y="bottom"
        />
        <!-- <a class="d-flex align-center text-caption color-link color-link-lighter_hover transition mr-20">
          <Icon name="document-text-small" />
          <span class="ml-8">Fast report</span>
        </a> -->
        <a
          :href="profileUrl"
          target="_blank"
          class="d-flex align-center text-caption color-link color-link-lighter_hover transition"
        >
          <Icon class="w-12px h-12px mb-3" :name="socialNetworkIcon" />
          <span class="ml-8">Profile page</span>
        </a>
      </div>
      <div class="text-caption color-link">
        {{ name }}
      </div>
    </div>
    <div class="d-grid gtc-300px-auto">
      <div>
        <Avatar class="w-100" :src="avatar" />
      </div>
      <div>
        <div class="pt-24 px-24 pb-12">
          <h1 class="text-h4">{{ name }}</h1>
          <div class="text-base">{{ location }}</div>
        </div>
        <div class="d-flex align-center bg-white b-bottom-double bc-body px-24">
          <button
            v-for="(tab, index) in tabsProfileInfo"
            :key="index"
            :disabled="tab.disabled"
            class="text-base transition b-bottom-double py-8 mr-20 mb--2"
            :class="{
              'color-text bc-transparent': !tab.active && !tab.disabled,
              'color-link fw-600 bc-link': tab.active && !tab.disabled,
              'color-text-lighter cursor-auto bc-transparent': tab.disabled
            }"
            @click.prevent="tabsProfileInfo.find(t => t.active).active = false; tab.active = true;"
          >
            {{ tab.label }}
          </button>
        </div>
        <div
          v-for="(tab, index) in tabsProfileInfo"
          v-show="tab.active"
          :key="index"
          class="h-100"
        >
          <TabsInfo
            v-if="tab.label === 'Profile info' && tab.active"
            :profile="profile"
            :bio="bio"
            :profileDetails="profileDetails"
            :show-updates="true"
            :height="height"
          />
          <TabsConnections
            v-if="tab.label === 'Social graph' && tab.active"
            :profile="profile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import TagTooltip from '@/components/UI/TagTooltip';
import Avatar from '@/components/app/Avatar';
import TabsInfo from '@/components/right-sidebar/tabs/Info';
import TabsConnections from '@/components/right-sidebar/tabs/Connections';
import profileInfoDetailMixin from '@/mixins/profileInfoDetailMixin';

export default {
  components: {
    Icon,
    Avatar,
    TagTooltip,
    TabsInfo,
    TabsConnections
  },
  props: ['profile', 'height'],
  mixins: [profileInfoDetailMixin],
  data () {
    return {
      tabsProfileInfo: [
        { label: 'Profile info', active: true, disabled: false },
        { label: 'Social graph', active: false, disabled: false }
        // { label: 'Content', active: false, disabled: true },
        // { label: 'Updates history', active: false, disabled: true }
      ]
    };
  }
};
</script>
